import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_sorokins_in_national_team_debut" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Vladislavs Sorokins (23 y.o.) and Alexejs Savelevs (21 y.o.) at the same time made their debut in the Latvian National Team in friendly match against San-Marino National Team, both footballers played whole game. The game ended with the score 0:3 in favor of the Latvian National Team.</p>
          <p>In the next official match against the Faroe Islands Team Alexejs Saveljevs was subbed in for 60 minutes and thus made his debut for the Latvian National Team in official matches. The game was ended with draw 1:1.</p>
          <p>In the last match of the League of Nations tournament the Latvian National Team played against the National Team of Andorra. The match was ended with the score 0:5 in favor of the Latvian National Team. Aleksejs Saveljevs came on as a substitute on the second half and spent 35 minutes on the pitch. Aleksejs took part in all three scheduled matches of the Latvian National Team in this international window.</p>
        </div>
      </div>
    )
  }
}